import React from "react";
import styles from "./about.module.scss";
import { motion } from "framer-motion";




const AboutPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="dev"
    >

      <h1 className={styles.pageTitle}>About</h1>

      <div className={styles.aboutBlurb}>
        <h3 className={styles.blurbHeading}>I develop software on the Web.</h3>

        <div className={styles.blurbText}>
          <p>For the past several years, I've collaborated in web projects both small and large,
            from small businesses to big ecommerce. I enjoy architecting great UX as I 
            transform creative designs into fast, responsive and fully living creatures on the Web.
          </p>

          <p>With tools as dynamic as the Web itself, my toolbelt is constantly changing, but 
            here are some of the larger technologies I work with on a regular basis:
          </p>

          <ul>
            <li>HTML/CSS</li>
            <li>ES6/7 JavaScript</li>
            <li>ReactJS</li>
            <li>SVG</li>
            <li>NodeJS/Express</li>
          </ul>

          <p className={styles.hobbiesTxt}>Aside from tinkering behind a computer, I'm a lover of the outdoors and can often
            be found scrambling up some mountain trails or planning my next cycling foray. 
          </p>
        </div>
      </div>

    </motion.div>
  )
}

export default AboutPage